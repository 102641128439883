import Layout from 'components/Layout/Layout';
import Container from 'components/Container/Container';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import React from 'react';

const Analytics = ({
  layoutStructure = {
    showBreadCrumbs: false,
    showTitle: false,
  },
}) => {
  return (
    <Layout title="Analytics" isAdmin layoutStructure={layoutStructure}>
      <Container>
        <Heading>Analytics</Heading>
      </Container>
    </Layout>
  );
};

export default Analytics;
